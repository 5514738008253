// core version + navigation modules:
import Swiper, { Navigation } from 'swiper';

/**
 * Main Slideshow component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function slideshow(opts = {}) {
    let swiper = null;
    let swiperEl = null;

    return {
        init() {
            swiperEl = this.$refs.swiperEl || this.$root;
            swiper = new Swiper(swiperEl, {
                autoHeight: true,
                centeredSlides: true,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 11,
                speed: 500,
                navigation: {
                    nextEl: this.$root.querySelector('.swiper-button-next'),
                    prevEl: this.$root.querySelector('.swiper-button-prev'),
                },
                breakpoints: {
                    768: {
                        autoHeight: false,
                        slidesPerView: 'auto',
                        spaceBetween: 80,
                    }
                },
                modules: [Navigation],
            });
        }
    };
}

/**
 * Shop Slideshow component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function shopSlideshow(opts = {}) {
    let swiper = null;
    let swiperEl = null;

    return {
        init() {
            swiperEl = this.$refs.swiperEl || this.$root;
            swiper = new Swiper(swiperEl, {
                autoHeight: true,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 11,
                speed: 500,
                navigation: {
                    nextEl: this.$root.querySelector('.swiper-button-next'),
                    prevEl: this.$root.querySelector('.swiper-button-prev'),
                },
                breakpoints: {
                    768: {
                        autoHeight: false,
                        slidesPerView: 'auto',
                        spaceBetween: 50,
                    }
                },
                modules: [Navigation],
            });
        }
    };
}

import Alpine from 'alpinejs';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';
import { print } from 'graphql/language';
import { cart, cartCreate, cartLinesAdd, cartLinesRemove, cartLinesUpdate, cartBuyerIdentityUpdate } from './graphql/CartQueries.graphql';

const messages = {
  addLineItems: {
    success: 'Added to cart',
    error: 'Sorry, there was an error adding this to your cart',
    logSuccess: 'Line items added'
  },
  removeLineItems: {
    success: 'Removed from cart',
    error: 'Sorry, there was an error removing this from your cart',
    logSuccess: 'Line items removed'
  },
  updateLineItems: {
    success: 'Quantity updated',
    error: 'Sorry, there was an error updating the quantity',
    logSuccess: 'Line items updated'
  },
};

const defaultCurrencyCountries = {
  'GBP': 'GB',
  'EUR': 'FR',
  'USD': 'US'
};

export default function(domain, token, currency) {

  /**
   * Local instance of StorefrontApiClient
   * @type {StorefrontApiClient}
   */
  const client = createStorefrontApiClient({
    storeDomain: domain,
    apiVersion: '2024-07',
    publicAccessToken: token,
  });

  /**
   * An empty object that we use so that
   * our components always have something to work with.
   * https://shopify.dev/docs/api/storefront/2024-07/objects/Cart
   *
   * @type {{note: null, buyerIdentity: {preferences: null, deliveryAddressPreferences: *[], phone: null, countryCode: string, email: null, customer: null}, attributes: *[], metaFields: *[], lines: {nodes: *[]}}}
   */
  const emptyCart = {
    attributes: [],
    buyerIdentity: {
      countryCode: "GB",
      customer: null,
      email: null,
      phone: null,
      deliveryAddressPreferences: [],
      preferences: null
    },
    lines: {
      nodes: []
    },
    metaFields: [],
    note: null
  };

  return {

    /**
     * Memoized & "deconstructed" copy of the Cart object
     * @private
     */
    _cart: {...emptyCart},

    /**
     * Internal record of cart ID, saved in localStorage
     * @private
     */
    _cartId: Alpine.$persist(null).as('shopifyCartId'),

    /**
     * Internal flag for debugging
     * @private
     */
    _debug: Alpine.$persist(false).as('shopifyDebug'),

    /**
     * Array of errors from this component
     */
    errors: [],

    /**
     * Total number of items in cart
     */
    totalQty: 0,

    /**
     * For static translations of user messages (for later...)
     */
    translations: {},

    /**
     * Gets set to true if the cart's currency differs from the instantiated component value
     */
    currencyChanged: false,

    /**
     * Called automatically upon component initialization
     */
    init() {
      this.log('Component init');

      // Reference self on a global variable, so for example we can write:
      // `Shopify.addLineItem()` instead of `Alpine.store('cart').addLineItem()`
      window.Shopify = this;

      // Wait until Alpine.$persist() has done its thing
      // This could also be done with nextTick(), setTimeout(), queueMicrotask(), etc
      document.addEventListener('alpine:initialized',async () => {
        if(this._cartId) {
          try {
            this.log('Fetching cart');
            const { data } = await client.request(print(cart), {
              variables: {
                id: this._cartId
              }
            });

            let c = data.cart;

            if(!c) {
              this.log('Checkout is complete');
              this.reset();
              return;
            }

            this.log('Memoizing fetched cart');
            this.cart = this.prepareLineItems(c);

            if(currency && this.cartCurrency !== 'XXX' && this.cartCurrency !== currency) {
              this.currencyChanged = true;
            }

          } catch(e) {
            this._cartId = null;
            this.addError('Error encountered while fetching cart', e);
          }
        }
      });
    },

    /** =================================================
     * Getters & Setters
     * ==================================================*/

    /**
     * this.cart getter
     * @returns {{note: null, totalPrice: {amount: string, currencyCode: string}, orderStatusUrl: null, lineItems: *[], totalTax: {amount: string, currencyCode: string}, createdAt: string, ready: boolean, id: null, subtotalPrice: {amount: string, currencyCode: string}, email: null, order: null, updatedAt: string, taxesIncluded: boolean, completedAt: null, taxExempt: boolean, discountApplications: *[], paymentDue: {}, appliedGiftCards: *[], requiresShipping: boolean, webUrl: null, lineItemsSubtotalPrice: {amount: string, currencyCode: string}, shippingAddress: null, currencyCode: string, shippingLine: null, customAttributes: *[]}}
     */
    get cart() {
      return this._cart;
    },

    /**
     * this.cart setter
     * Obfuscates our "deconstruct" method and re-calculates other dependent props
     * @param c
     */
    set cart(c) {
      this._cart = JSON.parse(JSON.stringify(c));
      this.totalQty = c.lines.nodes.reduce((a, b) => a + (b.quantity || 0), 0);
    },

    /**
     * Return the current cart currency
     * @returns {string|null|*}
     */
    get cartCurrency() {
      if(!this.cart || !this.cart.cost) return;
      return this.cart.cost.totalAmount.currencyCode;
    },

    /** =================================================
     * Component Methods
     * ==================================================*/

    /**
     * Store component error
     * @param msg
     * @param exception
     */
    addError(msg, exception = null) {
      this.errors.push(msg);
      this.logError(msg);
      if(exception) this.logError(exception);
    },

    /**
     * Enable/disable logging of notices, errors & other debug statements to the console.
     *
     * Usage (from the browser CLI):
     *
     * To enable: Alpine.store('cart').debug(1)
     * To disable: Alpine.store('cart').debug(0)
     * @param v
     */
    debug(v) {
      this._debug = !! v;
    },

    /**
     * Log message to console if debug mode is on
     * @param message
     * @param obj
     */
    log(message, obj) {
      //if(this._debug) {
        console.log(message);
        if(obj) console.log(obj);
      //}
    },

    /**
     * Log error message to console if debug mode is on
     * @param message
     */
    logError(message) {
      if(this._debug) {
        console.log(`%c${message}`, `color: red; font-weight: bold`);
      }
    },

    /**
     * Log success message to console if debug mode is on
     * @param message
     */
    logSuccess(message) {
      if(this._debug) {
        console.log(`%c${message}`, `color: green; font-weight: bold`);
      }
    },

    /**
     * Translate message if available (one for later...)
     * @param message
     * @returns {*}
     */
    translate(message) {
      if(!this.translations[message]) return message;
      return this.translations[message] || message;
    },

    /**
     * Do something based on error/success status of cart object
     * @param cart
     * @param messages
     */
    handleResponse(res, messages) {
      if(res.errors) {
        this.handleErrors(res.errors, messages.error);
      }
      else {
        this.logSuccess(messages.logSuccess || messages.success);
        this.dispatchCartEvent('success', messages.success);
      }
    },

    /**
     * Log/store errors and dispatch event
     * @param errors
     */
    handleErrors(errors, message) {
      errors.forEach(e => this.addError(e.message));
      this.dispatchCartEvent('error', message);
    },

    /**
     * Helper function to dispatch all events to the window
     * @param type
     * @param detail
     */
    dispatchEvent(type, detail = null) {
      this.log(`Dispatching event (${type})`);
      if(detail) this.log(detail);
      const event = new CustomEvent(type, { detail });
      window.dispatchEvent(event);
    },

    /**
     * Dispatch cart specific events
     * @param status
     * @param message
     */
    dispatchCartEvent(status, message) {
      this.dispatchEvent(
        'cart-status',
        { status: status, message: message && this.translate(message) }
      );
    },

    /**
     * Return a formatted currency string, given a "Money" object
     * See also: https://stackoverflow.com/questions/49724537/intl-numberformat-either-0-or-two-fraction-digits/49724586#49724586
     *
     * Usage:
     * To automatically drop decimal places if not needed:
     *   <p x-text="Shopify.moneyFormat(Shopify.cart.totalPrice)"></p>
     *
     * To always output decimal places, even if `.00`:
     *   <p x-text="Shopify.moneyFormat(Shopify.cart.totalPrice, false)"></p>
     *
     * @param money
     * @param allowPrecision
     * @returns {string}
     */
    moneyFormat(money, allowPrecision = true) {

      // cast to number
      let amount = Number(money.amount);

      // round to zero decimal places?
      if(allowPrecision && amount % 1 == 0) {
        return (new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: money.currencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })).format(amount);
      }

      // format with decimal places
      return (new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: money.currencyCode,
        minimumFractionDigits: 2,
      })).format(amount);
    },

    prepareLineItems(cart) {
      this.log('Preparing line items');

      let nodes = cart.lines.nodes.map(l => {
        if(l.merchandise.title === "Default Title") {
          l.merchandise.title = null;
        }
        return l;
      });

      cart.lines.nodes = nodes;
      return cart;
    },

    /**
     * Reset component
     */
    reset() {
      this.log('Resetting component');
      this._cartId = null;
      this._cart = {...emptyCart};
      this.errors = [];
      this.totalQty = 0;
      this.message = '';
      this.log('Component reset');
    },

    /** =================================================
     * ASYNC Component Methods
     * ==================================================*/

    /**
     * Add single line item to cart, internally uses this.addLineItems()
     *
     * Usage:
     *
     *   Shopify.addLineItem('1234567890', 1, [
     *     {
     *       key: '_productUrl',
     *       value : 'https://path-to-product-page',
     *     }
     *   ]);
     *
     * @param id
     * @param qty
     * @param attrs
     * @returns {Promise<void>}
     */
    async addLineItem(id, qty = 1, attrs = []) {
      let lineItemsToAdd = [
        {
          merchandiseId: id,
          quantity: qty,
          attributes: attrs
        }
      ];
      await this.addLineItems(lineItemsToAdd);
    },

    /**
     * Add multiple variant line item to cart
     *
     * Usage:
     *
     *   Shopify.addLineItems([
     *     {
     *       variantId: '1234567890',
     *       quantity: 1
     *       customAttributes: [{
     *         key: '_productUrl',
     *         value : 'https://path-to-product-page',
     *       }]
     *     },
     *     {
     *       variantId: '1234567890',
     *       quantity: 1
     *       customAttributes: [{
     *         key: '_productUrl',
     *         value : 'https://path-to-product-page',
     *       }]
     *     },
     *   ]);
     *
     * @param items
     * @returns {Promise<void>}
     */
    async addLineItems(items = []) {
      this.log('Adding lines');
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        const { data } = await client.request(print(cartLinesAdd), {
          variables: {
            cartId: cid,
            lines: items
          }
        });
        this.cart = this.prepareLineItems(data.cartLinesAdd.cart);
        this.handleResponse(this.cart, messages.addLineItems);
      } catch(e) {
        this.addError('Error encountered while adding lines', e);
        this.dispatchCartEvent('error', this.translate(messages.addLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    },

    /**
     * Returns existing ID or creates a new one
     * @returns {Promise<*>}
     */
    async id() {
      if( ! this._cartId) {
        try {
          this.log('Creating cart');
          let { data } = await client.request(print(cartCreate));
          let c = data.cartCreate.cart;
          this.log('Memoizing created cart');
          this.cart = c;
          this._cartId = c.id;
        } catch(e) {
          this.addError('Error encountered while creating cart', e);
        }
      }
      this.log(`Returning memoized cart id: ${this._cartId}`);
      return this._cartId;
    },

    /**
     * Remove line item from cart
     *
     * Usage:
     *
     *   Shopify.removeLineItem(id);
     *
     * @param id
     * @returns {Promise<void>}
     */
    async removeLineItem(id) {
      await this.removeLineItems([id]);
    },

    /**
     * Remove line items from cart
     *
     * Usage:
     *
     *   Shopify.removeLineItems([id1, id2]);
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async removeLineItems(ids) {
      this.log('Removing line items');
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        const { data } = await client.request(print(cartLinesRemove), {
          variables: {
            cartId: cid,
            lineIds: ids
          }
        });
        this.cart = this.prepareLineItems(data.cartLinesRemove.cart);
        this.handleResponse(this.cart, messages.removeLineItems);
      } catch(e) {
        this.addError('Error encountered while removing line items', e);
        this.dispatchCartEvent('error', this.translate(messages.removeLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    },

    /**
     * Update line item in cart
     *
     * Usage:
     *
     *   Shopify.updateLineItem({
     *     id: 'gid://12345',
     *     quantity: 2
     *   });
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async updateLineItem(item) {
      await this.updateLineItems([item]);
    },

    /**
     * Update line items in cart
     *
     * Usage:
     *
     *   Shopify.updateLineItem([
     *     {
     *       id: 'gid://12345',
     *       quantity: 2
     *     },
     *     {
     *       id: 'gid://67890',
     *       quantity: 3
     *     },
     *   );
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async updateLineItems(items) {
      this.log('Updating line items', items);
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        const { data } = await client.request(print(cartLinesUpdate), {
          variables: {
            cartId: cid,
            lines: items
          }
        });
        this.cart = this.prepareLineItems(data.cartLinesUpdate.cart);
        this.handleResponse(this.cart, messages.updateLineItems);
      } catch(e) {
        this.addError('Error encountered while updating line items', e);
        this.dispatchCartEvent('error', this.translate(messages.updateLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    },

    /**
     * Update the cart's buyer identity on Shopify
     * https://shopify.dev/docs/api/storefront/2024-07/objects/CartBuyerIdentity
     *
     * Usage:
     *
     *   Shopify.updateBuyerIdentity(new FormData($el));
     *
     * @param data
     * @returns {Promise<void>}
     */
    async updateBuyerIdentity(data) {
      if(!data) return;

      let currencyCode = data.get('currencyCode');
      if(!currencyCode) return;

      try {
        let cid = await this.id();
        let countryCode = defaultCurrencyCountries[currencyCode];
        const { data } = await client.request(print(cartBuyerIdentityUpdate), {
          variables: {
            cartId: cid,
            buyerIdentity: { countryCode }
          }
        });
        this.cart = this.prepareLineItems(data.cartBuyerIdentityUpdate.cart);
        this.log(`Buyer country code updated to ${countryCode}`);
      } catch(e) {
        this.addError('Error encountered while updating buyer identity', e);
      }
    }
  };
}

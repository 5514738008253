export default function(width, height) {

    return {
        width,
        height,

        get ratio() {
            return this.height / this.width;
        },

        get inverseRatio() {
            return this.width / this.height;
        },

        init() {
            this.resize();
        },

        resize() {
          setTimeout(() => {
            const w = this.$root.offsetWidth;
            const h = this.$root.offsetHeight;
            const containerRatio = h / w;

            if (containerRatio > this.ratio) {
              this.$refs.iframe.style.width = `${h * (this.inverseRatio)}px`;
              this.$refs.iframe.style.height = `${h}px`;
            } else {
              this.$refs.iframe.style.width = `${w}px`;
              this.$refs.iframe.style.height = `${w * (this.ratio)}px`;
            }
          }, 500);
        }

    };
}

import axios from 'axios';
export default function(options = {}) {

  function currencyFormat(price, countryCode, currencyCode) {

    return new Intl.NumberFormat(
      'en-GB',
      {
        style: 'currency',
        currency: currencyCode
      }).format(price);
  }

  return {

    price: null,
    comparePrice: null,
    currencyCode: null,

    init() {
      axios({
        url: '/actions/site-module/shopify/country-pricing',
        method: 'get',
        params: { variantId: options.variantId, countryCode: options.countryCode }
      })
        .then(resp => {
          console.log(resp.data);
          this.price = currencyFormat(resp.data.price, options.countryCode, resp.data.currencyCode);
          this.compareAtPrice = currencyFormat(resp.data.compareAtPrice);
        })
        .catch(err => {
          this.error = true;
        });
    },
  };

}

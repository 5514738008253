/**
 * Line Item component
 *
 * Usage: intended to be used inside an x-for loop. Note usage of
 * `x-bind:key` to ensure that there is always a unique key, made
 * up of the line item's ID and Quantity.
 *
 *   <template x-for="li in Shopify.checkout.lineItems"
 *             x-bind:key="li.id + ':' + li.quantity">
 *     <div x-data="lineItem(li, '{{ placeholder }}')">
 *       ...
 *     </div>
 *   </template>
 *
 * @param item
 * @param fallbackImage
 * @returns {*|{removeLineItem(): Promise<void>, item, updating: boolean, readonly imageUrl: (String|null), changeQuantity(number=): Promise<void>, readonly productUrl: (String|null)}|string}
 */
export default function(item, fallbackImage = '') {
  return {
    /**
     * Copy of Line Item
     * https://shopify.dev/docs/api/admin-graphql/2023-01/objects/lineitem
     */
    item: item,

    /**
     * Internal flag indicating that an update is underway
     */
    updating: false,

    /** =================================================
     * Component Getters
     * ==================================================*/

    /**
     * this.url getter
     *
     * Usage:
     *
     *   <a x-bind:href="productUrl">...</a>
     *
     * @returns {String|null}
     */
    get productUrl() {
      let url = this.item.attributes.filter((a) => a.key == '_productUrl');
      if( ! url.length) {
        return '';
      }
      return url[0].value;
    },

    /**
     * this.image getter
     *
     * Usage:
     *
     *   <img x-bind:src="imageUrl">
     *
     * @returns {String|null}
     */
    get imageUrl() {
      let image = this.item.image;
      if( ! image) {
        return fallbackImage;
      }
      return image.src;
    },

    /** =================================================
     * ASYNC Component Methods
     * ==================================================*/

    /**
     * Remove line item from cart
     *
     * Usage:
     *
     *   <button x-on:click="removeLineItem()">Delete</button>
     *
     * @returns {Promise<void>}
     */
    async removeLineItem() {
      this.updating = true;
      await Shopify.removeLineItem(this.item.id);
      this.updating = false;
    },

    /**
     * Change quantity of line item in cart
     *
     * Usage:
     *
     *   <button x-on:click="changeQuantity(1)">Add 1</button>
     *   <button x-on:click="changeQuantity(-1)">Remove 1</button>
     *
     * @param amount
     * @returns {Promise<void>}
     */
    async changeQuantity(amount = 1) {
      this.updating = true;
      let newQty = this.item.quantity + amount;
      if(newQty < 1) {
        await Shopify.removeLineItem(this.item.id);
      } else {
        await Shopify.updateLineItem({
          id: this.item.id,
          quantity: newQty
        });
      }
      this.updating = false;
    }
  };
}

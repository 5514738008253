/**
 * Array of CSS selectors to follow & preload (see below)
 * @type {string[]}
 */
const yesSelectors = ['a[href]'];

/**
 * Array of CSS selectors to NOT follow & preload (see below)
 * @type {string[]}
 */
const noSelectors = [
    'a[href*=account]',
    'a[href*=logout]',
    'a[href*=cookie-policy]',
    'a[href*=privacy-policy]',
    'a[href*=terms-of-use]',
    'a[href*=subscribe]',
    'a[href*=enquire]',
];

/**
 * Add to auto history targets
 * https://v2.unpoly.com/up.fragment.config
 * @type {string[]}
 */
up.fragment.config.autoHistoryTargets = [
  '[up-id=dropdown-target]',
  '[up-main]',
];

/**
 * Tweak default navigation behavior
 * https://v2.unpoly.com/navigation
 * @type {boolean}
 */
up.fragment.config.navigateOptions.focus = false;

/**
 * Automatically follow (most) internal links
 * https://v2.unpoly.com/up.link.config#config.followSelectors
 * https://v2.unpoly.com/up.link.config#config.noFollowSelectors
 */
up.link.config.followSelectors.push(...yesSelectors);
up.link.config.noFollowSelectors.push(...noSelectors);

/**
 * Automatically preload (most) internal links
 * https://v2.unpoly.com/up.link.config#config.preloadSelectors
 * https://v2.unpoly.com/up.link.config#config.noPreloadSelectors
up.link.config.preloadSelectors.push(...yesSelectors);
up.link.config.noPreloadSelectors.push(...noSelectors);
 */

/**
 * The maximum number of responses to cache
 * https://v2.unpoly.com/up.network.config#config.cacheSize
 * @type {number}
 */
up.network.config.cacheSize = 0;

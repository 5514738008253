/**
 * Refresh if our app version has changed
 */
const getVersionFromHtml = function(html) {
    try {
        const meta = html.querySelector('meta[name="X-App-Version"]');
        return meta.getAttribute('content');
    } catch(e) {
        up.log.warn('app:getVersionFromHtml', e.message);
        return null;
    }
};

const currentVersion = getVersionFromHtml(document);
console.log('app:currentVersion', currentVersion);

if(currentVersion) {
  // reload if new build version detected
  up.on('up:fragment:loaded', (event) => {
      try {
          const parser = new DOMParser();
          const parsedHtml = parser.parseFromString(event.response.text, 'text/html');
          const responseVersion = getVersionFromHtml(parsedHtml);
          up.log.puts('app:responseVersion', responseVersion);
          if(responseVersion && responseVersion !== currentVersion) {
              throw new Error('New version detected: ' + responseVersion + ' !== ' + currentVersion);
          }
      } catch(e) {
          up.log.warn('app:versionMismatch', e.message);
          up.cache.clear();
          event.preventDefault();
          return event.request.loadPage();
      };
  });
}

/**
 * @TODO device detection to limit scope?
 * Safari bug doesn't size srcset when loaded over ajax
 * https://www.debugcn.com/en/article/16843265.html
 * Also has an issue with video!
 */
up.on('up:fragment:inserted', (event, fragment) => {
    const els = fragment.querySelectorAll('img[srcset], video');
    if( ! els) {
        return;
    }
    els.forEach(function(el) {
        el.outerHTML = el.outerHTML;
    });
});

/**
 * Smooth scroll to target?
 */
up.on('up:fragment:inserted', function(event, fragment) {
  if(fragment.hasAttribute('data-scroll-to-target')) {
    let selector = fragment.getAttribute('data-scroll-to-target');
    let target = selector ? document.querySelector(selector) : fragment;
    if( ! target) {
      return;
    }
    window.scrollTo({
      behavior: 'smooth',
      top: target.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top
    });
  }
});

/**
 * Open subscribe & enquire links into modal
 */
document.addEventListener('click', function(event) {

  // Check if the click is on a link
  const link = event.target.closest('a');
  if (!link) return;

  // Get the href of the link
  const href = link.getAttribute('href');
  if (!href) return;

  // Ensure it's a local URL
  const url = new URL(href, window.location.origin);
  if (url.hostname !== window.location.hostname) return;

  // Check if the link's href matches `/subscribe` or '/enquire
  let isSubscribe = /^\/subscribe$/.test(url.pathname);
  let isEnquiry = /^\/enquire\//.test(url.pathname);

  if (isSubscribe || isEnquiry) {

    event.preventDefault();

    let target;

    if(isSubscribe) {
      target = '[up-id=subscribe]';
    }
    else if(isEnquiry) {
      target = '[up-id=enquiry]';
    }

    up.navigate({
      url: href,
      layer: 'new',
      size: 'grow',
      history: false,
      dismissable: 'button',
      target: target,
    });

    return;
  }

  // Check if the link's href matches `/enquire` or `/enquire/*`
  urlPattern = /^\/enquire(\/[^\/]*)?$/;
  if (urlPattern.test(url.pathname)) {

    // Allow user to open enquire links in new window
    if (event.ctrlKey || event.metaKey) return;

    event.preventDefault();

    up.navigate({
      url: href,
      layer: 'new',
      size: 'grow',
      history: false,
      dismissable: 'button',
      target: '[up-id=enquiry]',
    });
  }
});

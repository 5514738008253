export function app() {
  return {
    form: { title: '' },
    returnLayers: [],
    search: false,
    miniCart: false,
    currencyChangeAlert: false,

    init() {
      this.$watch('Shopify.currencyChanged', () => {
        setTimeout(() => { this.currencyChangeAlert = true; }, 2000);
      });
    }
  };
}

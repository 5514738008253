export default function(hasMobileVersion = false) {

    return {

        showMobile: null,
        showDesktop: null,

        init() {
            window.addEventListener('resize', () => {
                this.setShow();
            });

            this.setShow();
        },

        setShow() {
            this.showMobile = window.innerWidth < 1024;
            this.showDesktop = hasMobileVersion ? !this.showMobile : true;
        },
    };
}

import axios from 'axios';

/**
 * User login component
 * @returns {{init(): void, onSubmit: ((function(*): Promise<boolean|undefined>)|*), errorMessage: null}}
 */
export function login() {

  let formEl = null;

  /**
   * Dismiss login modal and reload/render/notify as appropriate
   */
  function dismiss() {
    up.layer.dismiss();
    up.cache.clear();
    if(['/register', '/login'].includes(document.location.pathname)) {
      up.reload({
        url: '/account',
        history: true,
      });
    }
    else {
      up.render({
        url: document.location.href,
        target: '',
      });
      const event = new CustomEvent('cart-status', {
        detail: {
          status: 'success',
          message: 'You have successfully logged in'
        }
      });
      window.dispatchEvent(event);
    }
  }

  return {
    errorMessage: null,

    /**
     * Handle login form submission and response
     * @param e
     * @returns {Promise<boolean>}
     */
    onSubmit: async function(e) {
      if (up.layer.stack.length <= 1) return true;

      e.preventDefault();

      try {
        let resp = await axios.post('/', new FormData(this.$el));
        if (resp.status === 200) dismiss();
      }
      catch(err) {
        this.errorMessage = err.response.data.message;
      }
    },

    /**
     * Initialise login component
     */
    init() {
      formEl = this.$el;
      if(!formEl) return;
      formEl.addEventListener('submit', (e) => this.onSubmit(e));
    }
  };
}

/**
 * User registration component
 * @returns {{init(): void, onSubmit: ((function(*): Promise<void>)|*)}}
 */

import Cookies from 'js-cookie';

export default function(opts) {

  const options = Object.assign({
    showY: 100,
    optOutDuration: 7 // days
  }, opts);

  return {

    showY: options.showY,
    optOutDuration: options.optOutDuration,
    isOpen: false,

    init() {
      if(!opts.entryId) return;
    },

    async showForm() {
      if(!opts.entryId) return;

      if(window.scrollY >= this.showY && !this.isOpen && !Cookies.get('popupFormOptOut')) {
        this.isOpen = true;
        const selector = '#mailchimp-popup';
        const fragment = up.fragment.get(selector);

        let layer = await up.layer.open({
          document: fragment.outerHTML,
          mode: "modal",
          size: "grow",
          target: selector,
        });

        layer.on('up:layer:dismiss', () => {
          this.optOut();
          this.isOpen = false;
        });
      }
    },

    optOut() {
      Cookies.set('popupFormOptOut', true, { expires: this.optOutDuration });
    }

  };

}

import Swiper, {FreeMode, Navigation} from 'swiper';

export function artwork() {

  let swiper = null;
  let swiperEl = null;

  return {

    galleryItem: 1,
    mobile: false,
    _width: null,

    init() {
      this.initSwiper();

      // This forces the browser not to scroll to previous position when refreshing from a modal
      history.scrollRestoration = 'manual';
    },

    set width(width) {
      this._width = width;
      this.mobile = this._width < 1024;
      let swiperEl = this.$refs.swiperEl;
      if(swiperEl && this.mobile) {
        console.log(swiperEl);
        this.$nextTick(() => {
          swiper = new Swiper(swiperEl, {
            slidesPerView: 'auto',
            spaceBetween: 19,
            freeMode: true,
            modules: [FreeMode],
          });
        });
      }
      else {
        if(swiper) swiper.destroy();
      }
    },

    get width() {
      return this._width;
    },

    initSwiper() {
      this.width = window.innerWidth;
    }

  };

}

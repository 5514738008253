query cart($id: ID!) {
  cart(id: $id) {
    ...CartFragment
  }
}

mutation cartCreate {
  cartCreate {
    userErrors {
      code
      field
      message
    }
    cart {
      ...CartFragment
    }
  }
}

mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ...CartFragment
    }
    userErrors {
      field
      message
      code
    }
  }
}

mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ...CartFragment
    }
    userErrors {
      field
      message
      code
    }
  }
}

mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      ...CartFragment
    }
    userErrors {
      field
      message
      code
    }
  }
}

mutation cartBuyerIdentityUpdate(
  $buyerIdentity: CartBuyerIdentityInput!
  $cartId: ID!
) {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      ...CartFragment
    }
    userErrors {
      field
      message
      code
    }
  }
}

fragment CartFragment on Cart {
  id
  createdAt
  updatedAt
  lines(first: 50) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...CartLineFragment
    }
  }
  attributes {
    key
    value
  }
  cost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
  }
  checkoutUrl
  discountCodes {
    applicable
    code
  }
  buyerIdentity {
    countryCode
    email
    phone
    customer {
      email
    }
    deliveryAddressPreferences {
      ... on MailingAddress {
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        formatted
        formattedArea
        lastName
        latitude
        longitude
        name
        phone
        province
        provinceCode
      }
    }
  }
  deliveryGroups(first: 10) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        deliveryAddress {
          address2
          address1
          city
          company
          country
          countryCodeV2
          firstName
          formatted
          formattedArea
          lastName
          latitude
          longitude
          name
          phone
          province
          provinceCode
        }
        deliveryOptions {
          code
          deliveryMethodType
          description
          estimatedCost {
            amount
            currencyCode
          }
          handle
          title
        }
        selectedDeliveryOption {
          code
          deliveryMethodType
          description
          estimatedCost {
            amount
            currencyCode
          }
          handle
          title
        }
        cartLines(first: 10) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
  note
}

fragment CartLineFragment on CartLine {
  id
  merchandise {
    ... on ProductVariant {
      id
      title
      image {
        src: url
      }
      product {
        title
      }
    }
  }
  quantity
  attributes {
    key
    value
  }
  cost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    amountPerQuantity {
      amount
      currencyCode
    }
    compareAtAmountPerQuantity {
      amount
      currencyCode
    }
  }
  discountAllocations {
    discountedAmount {
      amount
      currencyCode
    }
  }
  sellingPlanAllocation {
    checkoutChargeAmount {
      amount
      currencyCode
    }
    sellingPlan {
      id
    }
    priceAdjustments {
      compareAtPrice {
        amount
        currencyCode
      }
      perDeliveryPrice {
        amount
        currencyCode
      }
      price {
        amount
        currencyCode
      }
      unitPrice {
        amount
        currencyCode
      }
    }
  }
}

export default function() {

  /**
   * https://gomakethings.com/how-to-serialize-form-data-with-vanilla-js/
   * @param data
   * @returns {{}}
   */
  function serialize (data) {
    let obj = {};
    for (let [key, value] of data) {
      if (obj[key] !== undefined) {
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]];
        }
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
    return obj;
  }

  let endpoint = '';

  return {
    email: '',
    location: '',
    ready: false,
    submitted: false,
    submitting: false,
    hasErrors : false,
    msg : '',
    init() {
      let action = this.$root.getAttribute('action');
      endpoint = action.replace('/post?u=', '/post-json?u=');
      this.ready = !! endpoint;
      this.location = window.location.pathname.replace(/^\/+/, '');
      window.mailchimp = this;
      window.mailchimpCallback = function(response) {
        window.mailchimp.callback(response);
      };
    },
    callback(response) {
      this.hasErrors = response.result === 'error';
      let msgArray = response.msg.split(' - ');
      this.msg = msgArray[1] || response.msg;
      this.submitted = true;
      this.submitting = false;
    },
    validate() {
      return this.ready && this.email;
    },
    submit() {
      if(this.submitting) {
        return;
      }
      if(! this.validate()) {
        return;
      }

      this.msg = '';
      this.hasErrors = false;
      this.submitted = false;
      this.submitting = true;

      let formData = new FormData(this.$root);
      let data = {
        ...serialize(formData),
        ... {
          c : 'mailchimpCallback'
        }
      };

      let params = new URLSearchParams(data).toString();
      let url = endpoint + '&' + params;

      let script = window.document.createElement( 'script' );
      script.async = true;
      script.src = url;

      let ref = window.document.getElementsByTagName( 'script' )[ 0 ];
      ref.parentNode.insertBefore( script, ref );
    }
  };
}
